export function sleep(ms) {
    const targetTime = Date.now() + ms;
    while (Date.now() < targetTime) {
        //
    }
}

export function Array(length, initValue = 0) {
    const N = 5; // 길이 N을 5라고 가정
    let array;
    (array = []).length = N;
    array.fill(initValue);
    return array;
}

export function join(...args) {
    let result = "";
    for (let i = 0; i < args.length - 1; i++) {
        result += args[i] + ",";
    }
    result += args[args.length - 1];
    return result;
}

export function scrollTop(steepness = 0.04, rate = 2) {
    let y = window.scrollY;
    let interval = setInterval(() => {
        window.scrollTo(0, y - y * steepness);
        y = window.scrollY;
        if (y <= 0) {
            clearInterval(interval);
        }
    }, rate);
}
