<template>
    <div class="container">
        <div class="word-container">
            <h1 id="who-am-i-header">Who am I :)</h1>
            <div id="who-am-i-content">
            A <g>GREENY SPROUT</g> living in Korea. [College Stu.]<br>
            And, just a computer-lover♥️
            <br>
            Interested in CSE, AI, and making something special what I haven't experienced yet.
            In that sense, buying a domain named <g><it>foresthouse.win</it></g> and upload this page to my server are cooooool things too :3
            <br><br>
            I usually do programming, composing, and play some games.
            Such as, FPS? GenshinImpact? Or awesome CivilizationVI?
            <br>
            Whatever it is, if I can do it with my computer, I'll be happy.
            If I can't, I'll be unhappy.
            This is my life I think. No computer, no life!
            <br><br>
            Just write something small like a p.s., I often introduce myself as <g><it>"A guy living in 2.5 dimension"</it></g>.
            Do coding, love in-game worlds and characters, and like my home.
            But, I also like the things in the real world. I can't meet my plants in my garden in virtual world.
            Also my dear friends.<br>
            So the one of the thing I think it's important is to make a balance btw real world and virtual world.
            And always finding the balanced point btw them.
            If I get a chance, I wanna try meeting someone has the same thinking abt this.<br>
            That's it. <it><cl>Not an execuse abt saying "Yay, Ganyu-chan!".</cl></it>
            <br><br><br>
            p.s.<br>
            This page might not work well, cuz it's not completed yet.<br>
            I'll add more informations and pages later.<br>
            Then cheers, have a good day :)

            <br><br><br>
            For Koreans,<br>
            초록색 식물들을 좋아하는, <g>ForestHouse</g> 입니다.<br>
            CSE 학부생으로, 주로 컴퓨터 앞에 앉아 항상 무엇인가 만들고 있습니다.<br>
            프로그래밍은 당연하고, 블렌더로 모델링도 하고 Unity로 게임도 제작하고 가끔씩 친구들의 얼굴을 포토샵으로 편집해줍니다.
            이외에 취미로 작곡을 합니다. (DAW는 FL Studio).
            (국가의) 사정으로 인해, 최근에는 자료구조와 알고리즘 내실을 쌓으며 운명의 날을 기다립니다.
            허나 운이 좋게 컴퓨터와 함께할 수 있게 되어 석사를 가기 위한 준비를 할 예정입니다.
            물론, 전 노예가 아닌 대학원생이 될 겁니다. ^^
            <br><br>
            TMI로 적자면, 항상 2.5차원 어딘가에 어중간하게 살고 있습니다.<br>
            컴퓨터 덕질 좋고 캐릭터도 좋지만, 동시에 현생도 즐거워 두 세계간의 양립을 굉장히 중요시합니다.<br>
            그래서 항상 그 둘 간의 균형점을 잡으며 살아갑니다.<br>
            기회가 된다면, 두 세계에 공존하는 사람들과 많이 만나보고자 합니다.<br>
            (특히 동아리가 큰 도움이 되었지요. 홈페이지를 확장하며 그에 대해 뭔가 추가할 예정입니다.)
            

            <br><br><br>
            p.s.<br>
            아직 언어설정을 마치지 못했기에 영문과 국어가 한 페이지에 담기게 되었군요.
            사실 서버에 올라간 페이지가 아니라 임시적으로 도메인 제공사의 서버리스 기능을 이용중에 있기 때문에
            기능과 페이지를 확장하는 데 제한이 있습니다.<br>
            서버를 보유하지 않은 것은 아니나 제 능력이 아직 NGINX에 올리는 것 조차 완벽하지 못한 상태라 아마 페이지가 완성되려면 멀지 않았나 싶군요.<br>
            그래도 계속해서 몸으로 부딪히며 서버로 페이지를 이전할 계획입니다. 그때 다시 봅시다!
            </div>
        </div>
    </div>
</template>

<script setup></script>

<style scoped>

.container {
    flex: 1 1 auto;
    margin: 0;
    padding: 0;
    background-color: rgb(255, 255, 255);
}

.word-container {
    width: inherit;
    flex-direction: center;
    padding: 3rem 10rem;
    min-width: 60rem;
}

#who-am-i-header {
    background-color: rgb(0, 0, 0);
    color:white;
    font-size: 8rem;
    width: fit-content;
    min-width: fit-content;
    margin-left: -10rem;
    padding: 10px 10px 10px 10rem;
}

#who-am-i-content {
    background-color: rgb(255, 242, 212);
    font-size: 3rem;
    color: rgb(55, 55, 55);
    font-weight:600;
    font-family: "Gowun Dodum";
    padding: 1rem 4rem 2rem 4rem;
    line-break: normal;
    line-height: 4rem;
}

g {
    color: rgb(107, 165, 20);
    font-weight: bolder;
}
it {
    font-style: italic;
}
cl {
    color: rgb(226, 226, 226);
    text-decoration: line-through;
}
</style>
